<template>
  <div class="about-container ab">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">About LeadSpeed</h1>
        <p class="fs-20">
          <b class="fs-20"
            >Shanghai LeadSpeed International Logistic Co.，LTD</b
          >
          is a professional, dynamically driven forwarding Company locate in
          Shanghai, China.
        </p>
      </div>
      <div class="banner"></div>
      <div class="third ptb-100">
        <div class="df">
          <div><img src="../assets/img/about/a2.jpg" alt="" /></div>
          <div>
            <p class="fs-20">
              We have grown to become a significant force among independent
              freight forwarders and our goal is to provide competitive shipping
              and air services from all main Chinese ports to all over the
              world. We tailor to the needs of our customers while maintaining
              one of the highest levels of customer service in the industry. We
              want to make every aspect of shipping your cargo as easy as
              possible.
            </p>
            <p>
              <el-button class="mt-30"
                ><a href="mailto:12345@163.com" class="color-white">
                  <span>Email</span>
                </a></el-button
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
.footer::before {
  content: none;
}
.ab .header .fr > ul {
  li.el-menu-item:nth-child(3),
  li li:nth-child(3) {
    background-color: #f47a2c;
    color: white;
    a {
      color: white;
    }
  }
}
</style>
